//  Threekit Proxy URLs
export const PROXY_DOMAIN_CHINA = 'https://threekit.lvcampaign.com';

//  Threekit Platform Routes
export const GET_CONFIGURAION_URL = `/api/configurations`;
export const POST_CONFIGURAION_URL = `/api/configurations`;
export const GET_DATATABLE_URL = `/api/datatables`;
export const GET_TRANSLATIONS_URL = `/api/products/translations`;
export const GET_CATALOG_ITEM_URL = `/api/catalog/products`;

export const ATTRIBUTE_SKU_METADATA_KEY = 'SKU';
export const ATTRIBUTE_ORDER_METADATA_KEY = '_order';
export const ATTRIBUTE_GROUPING_TABLE_METADATA_KEY = '_attrGroupingTableId';
export const VALUE_CODE_METADATA_KEY = 'valueCode';
export const VALUE_SKU_METADATA_KEY = 'valueSku';
export const SKU_ATTRIBUTE_NAME = '_SKU';

export const ATTRIBUTE_TYPES = {
  string: 'string',
  asset: 'asset',
  boolean: 'boolean',
};

/*****************************************************
 * Snapshot Config
 ****************************************************/

export const SNAPSHOT_FORMATS = {
  png: 'png',
  jpeg: 'jpeg',
};

export const DEFAULT_CAMERA_CONFIG = {
  attributeName: 'CameraAngle',
  size: { width: 1920, height: 1080 },
  format: SNAPSHOT_FORMATS.png,
};

/*****************************************************
 * Threekit Player initialization Defaults
 ****************************************************/

export const TK_PLAYER_DIV_ID = 'threekit-player';
export const TK_SAVED_CONFIG_PARAM_KEY = 'tkConfig';

export const DEFAULT_PLAYER_CONFIG = {
  authToken: undefined,
  elementId: undefined,
  cache: undefined,
  stageId: undefined,
  assetId: undefined,
  showConfigurator: false,
  initialConfiguration: undefined,
  showLoadingThumbnail: false,
  showLoadingProgress: true,
  onLoadingProgress: undefined,
  showAR: false,
  showShare: false,
  locale: undefined,
  allowMobileVerticalOrbit: true,
  publishStage: undefined,
};
