import React, { useEffect, useState } from 'react';
import Controller from './threekit';

const getParams = () => {
  let query = window.location.search.substr(1);
  return query.split('&').reduce((output, part) => {
    let [key, value] = part.split('=');
    if (!key?.length) return output;
    const preppedValue = decodeURIComponent(value);
    output[decodeURIComponent(key)] = preppedValue;
    return output;
  }, {});
};

function App() {
  const [form, setForm] = useState({});
  useEffect(() => {
    (async () => {
      const params = getParams();
      await Controller.launch({
        elementId: 'threekit-player',
        authToken: process.env.REACT_APP_PUBLIC_TOKEN,
        orgId: process.env.REACT_APP_ORG_ID,
        assetId:
          params?.itemId || params?.assetId || process.env.REACT_APP_ASSET_ID,
        attrGroupingTableId: process.env.REACT_APP_ATTR_GROUPING_TABLE_ID,
        threekitEnv: process.env.REACT_APP_THREEKIT_ENV,
        publishStage: 'draft',
        useProxy: window.location.href.includes('useProxy=true'),
      });
      const formData = await window.threekit.controller.getForm({
        groupAttributes: false,
      });
      setForm(formData);
    })();
  }, []);

  const handleClick = async (val) => {
    await window.threekit.configurator.setConfiguration(val);
    const formData = await window.threekit.controller.getForm({
      groupAttributes: false,
    });
    setForm(formData);
  };

  return (
    <div className="app">
      <div id="threekit-player" />
      <div>
        {Object.values(form).map((attr) => (
          <div>
            <div className="attribute-heading">{attr.name}</div>
            <div className="cards">
              {attr.values.map((opt, i) => {
                const selected = opt.assetId
                  ? opt.assetId === attr.value.assetId
                  : opt.value === attr.value;
                return (
                  <div
                    key={i}
                    className="card"
                    style={{
                      background: selected ? '#1890ff' : 'none',
                      color: selected ? 'white' : 'black',
                    }}
                    onClick={() =>
                      handleClick({
                        [attr.name]: opt?.assetId
                          ? { assetId: opt.assetId }
                          : opt.value,
                      })
                    }
                  >
                    <div>
                      {opt?.metadata?._thumbnail ? (
                        <img src={opt.metadata._thumbnail} alt={opt.name} />
                      ) : null}
                    </div>
                    <div>{opt.name}</div>
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default App;
