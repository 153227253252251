import { ATTRIBUTE_ORDER_METADATA_KEY } from './constants';

export const objectToQueryStr = (obj) => {
  if (!obj || !Object.keys(obj).length) return '';
  return Object.entries(obj).reduce((output, [key, val], i) => {
    if (i) output += '&';
    if (val !== undefined) output += `${key}=${val}`;
    return output;
  }, '?');
};

/**
 * Checks to see if a provided string is a valid
 * stringified JSON or not.
 */
const IsJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

/**
 * Function to get the params from the URL's query string
 * if present. The function can also take the key of a
 * specific param, if you require only the value associated
 * with that param from the URL query string.
 */
export const getParams = (param) => {
  let query = window.location.search.substr(1);
  const result = query.split('&').reduce((output, part) => {
    let [key, value] = part.split('=');
    if (!key?.length) return output;
    const preppedValue = decodeURIComponent(value);
    output[decodeURIComponent(key)] = IsJsonString(preppedValue)
      ? JSON.parse(preppedValue)
      : preppedValue;
    return output;
  }, {});
  if (!param) return result;
  return result[param];
};

/**
 * The Prep Attributes function takes as its parameter the output
 * from the window.threekit.configurator.getDisplayAttribtutes()
 * function and sorts the options for each of the part-reference
 * type attributes.
 *
 * The output data has the same structure as the input data, which
 * is the Threekit Configurator's Diplay Attributes output
 */
export const prepAttribute = (attribute) => {
  let prepped = attribute;
  if (attribute.values[0].metadata[ATTRIBUTE_ORDER_METADATA_KEY]) {
    prepped = Object.assign(attribute, {
      values: attribute.values.sort(
        (a, b) =>
          a.metadata[ATTRIBUTE_ORDER_METADATA_KEY] -
          b.metadata[ATTRIBUTE_ORDER_METADATA_KEY]
      ),
    });
  }
  return prepped;
};

export const dataURItoFile = (dataURI, filename) => {
  var arr = dataURI.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export const setCameraPosition = (cameraApi, cameraPosition) => {
  cameraApi.setPosition(cameraPosition.position);
  cameraApi.setQuaternion(cameraPosition.quaternion);
};

export const getCameraPosition = (cameraApi) => ({
  position: cameraApi.getPosition(),
  quaternion: cameraApi.getQuaternion(),
});

export const regularToKebabCase = (str) =>
  !str?.length
    ? ''
    : str
        .split(' ')
        .filter((word) => word?.length)
        .map((word) => word.trim().toLowerCase())
        .join('-');
