import * as http from './http';
import * as utils from './utils';
import connection from './connection';
import {
  DEFAULT_PLAYER_CONFIG,
  TK_SAVED_CONFIG_PARAM_KEY,
  ATTRIBUTE_GROUPING_TABLE_METADATA_KEY,
  ATTRIBUTE_SKU_METADATA_KEY,
  VALUE_CODE_METADATA_KEY,
  VALUE_SKU_METADATA_KEY,
  ATTRIBUTE_TYPES,
  SNAPSHOT_FORMATS,
  DEFAULT_CAMERA_CONFIG,
  SKU_ATTRIBUTE_NAME,
} from './constants';
import { dataURItoFile, setCameraPosition, getCameraPosition } from './utils';

class Controller {
  constructor({
    config,
    // player,
    configurator,
    translations,
    language,
    attributeGrouping,
  }) {
    //  Threekit API
    // this._api = threekitAPI;
    // this._player = player.enableApi('player');
    //  Translations
    this._config = config;
    this._translations = translations;
    this._currentLanguage = language;
    //  Attribute Grouping
    this._attributeGrouping = Object.entries(attributeGrouping || {}).reduce(
      (output, [groupName, attrs]) =>
        Object.assign(output, {
          [groupName]: attrs.map((el) => el.trim()),
        }),
      {}
    );
    this._codeData = Object.entries(configurator.getMetadata()).reduce(
      (output, [key, value]) => {
        if (
          !key.includes('.code') &&
          !key.includes('.type') &&
          !key.includes(`.${VALUE_CODE_METADATA_KEY}`) &&
          !key.includes(`.${VALUE_SKU_METADATA_KEY}`)
        )
          return output;

        const [attrName, dataKey, stringKey] = key.split('.');

        if (dataKey === VALUE_CODE_METADATA_KEY) {
          const val = { [stringKey]: value };
          if (output[attrName]) {
            output[attrName].codeValues = Object.assign(
              {},
              output[attrName]?.codeValues,
              val
            );
          } else output[attrName] = { codeValues: val };
        } else if (dataKey === VALUE_SKU_METADATA_KEY) {
          const val = { [stringKey]: value };
          if (output[attrName]) {
            output[attrName].skuValues = Object.assign(
              {},
              output[attrName]?.skuValues,
              val
            );
          } else output[attrName] = { skuValues: val };
        } else {
          if (output[attrName]) output[attrName][dataKey] = value;
          else output[attrName] = { [dataKey]: value };
        }

        return output;
      },
      {}
    );
  }

  static createPlayerLoaderEl() {
    let playerElement = document.getElementById('tk-player-root');
    if (playerElement) return playerElement;

    playerElement = document.createElement('div');
    playerElement.setAttribute('id', 'tk-player-root');
    playerElement.style.height = '100%';

    const playerLoader = document.createElement('div');
    playerLoader.appendChild(playerElement);
    playerLoader.style.opacity = '0';
    playerLoader.style.position = 'fixed';
    playerLoader.style.opacity = '-100%';

    document.body.appendChild(playerLoader);
    return playerElement;
  }

  static createThreekitScriptEl(threekitEnv, useProxy) {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      const scriptTitle = useProxy
        ? 'threekit-player-lvcampaign.js'
        : 'threekit-player-bundle.js';
      script.src = `${threekitEnv}/app/js/${scriptTitle}`;
      script.id = 'threekit-player-bundle';
      script.onload = () => resolve();
      document.head.appendChild(script);
    });
  }

  static initThreekit(config) {
    return new Promise(async (resolve) => {
      const player = await window.threekitPlayer(config);
      const configurator = await player.getConfigurator();
      resolve({ player, configurator });
    });
  }

  static attachPlayerToComponent(moveToElementId) {
    const addPlayer = (tryCount = 0) => {
      if (tryCount >= 10) return;

      let player = document.getElementById('player-root');
      const playerWrapper = document.getElementById(moveToElementId);

      if (!player || !playerWrapper)
        return setTimeout(() => {
          addPlayer(tryCount + 1);
        }, 0.05 * 1000);

      if (!player) throw new Error('Initial Player element not found');
      if (!playerWrapper) throw new Error('Move To element not found');

      playerWrapper.appendChild(player);
    };

    addPlayer();
  }

  static getConfiguration(configurationId) {
    return new Promise(async (resolve) => {
      if (!configurationId) {
        if (!window.threekit) return resolve();
        resolve(window.threekit.controller._player.getFullConfiguration());
      }
      const config = await http.getSavedConfiguration(configurationId);
      resolve(config);
    });
  }

  static async launch(config) {
    return new Promise(async (resolve) => {
      if (window.threekit) resolve();
      const {
        //  Threekit Player Init
        configurationId,
        authToken,
        orgId,
        elementId,
        cache,
        stageId,
        assetId,
        showConfigurator,
        initialConfiguration: initialConfigurationRaw,
        showLoadingThumbnail,
        showLoadingProgress,
        onLoadingProgress,
        showAR,
        showShare,
        locale,
        allowMobileVerticalOrbit,
        publishStage,
        //  Threekit Env (e.g. preview, admin-fts)
        threekitEnv: threekitEnvRaw,
        useProxy,
        attrGroupingTableId,
      } = Object.assign(DEFAULT_PLAYER_CONFIG, config);

      //  We get or create the player HTML element
      let el = document.getElementById(elementId);
      if (!el) el = this.createPlayerLoaderEl();

      //  Connection
      connection.connect({
        authToken,
        orgId,
        assetId,
        threekitEnv: threekitEnvRaw,
        useProxy,
      });

      //  We use the threekitEnv returned by the connection object
      //  As it ensures the env base url starts with 'https://'
      const { threekitEnv, proxyDomainChina } = connection.getConnection();

      //  Initial Configuration from Params
      let initialConfiguration = { ...initialConfigurationRaw };
      let updatedAssetId = assetId;
      let attrGroupingTableIdPrepped = attrGroupingTableId;
      const params = utils.getParams();

      if (configurationId || params[TK_SAVED_CONFIG_PARAM_KEY]?.length) {
        const configId = configurationId || params[TK_SAVED_CONFIG_PARAM_KEY];
        const configuration = await this.getConfiguration(configId);
        if (configuration) {
          initialConfiguration = Object.assign(
            {},
            initialConfigurationRaw,
            configuration.variant
          );
          connection.connect({ assetId: configuration.productId });
          updatedAssetId = configuration.productId;
        }
      }

      //  We check to see if the item to initialize has an over ride
      //  present for its attribute grouping
      const itemData = await http.getCatalogItem(updatedAssetId);
      if (itemData.metadata?.[ATTRIBUTE_GROUPING_TABLE_METADATA_KEY])
        attrGroupingTableIdPrepped =
          itemData.metadata[ATTRIBUTE_GROUPING_TABLE_METADATA_KEY];

      //  We create the threekit script
      await this.createThreekitScriptEl(
        useProxy ? proxyDomainChina : threekitEnv,
        useProxy
      );

      const [{ player, configurator }, translations, attributeGrouping] =
        await Promise.all([
          this.initThreekit({
            el,
            authToken,
            orgId,
            cache,
            stageId,
            assetId: updatedAssetId,
            showConfigurator,
            initialConfiguration,
            showLoadingThumbnail,
            showLoadingProgress,
            onLoadingProgress,
            showAR,
            showShare,
            locale,
            allowMobileVerticalOrbit,
            publishStage,
          }),
          http.getTranslations(),
          http.getAttributeGrouping(attrGroupingTableIdPrepped),
        ]);

      window.threekit = {
        player,
        configurator,
        controller: new Controller({
          config: { authToken, threekitEnv },
          player,
          configurator,
          translations: translations,
          language: locale,
          attributeGrouping,
        }),
      };
      resolve();
    });
  }

  _translateAttribute(attr) {
    if (!this._translations)
      return Object.assign({}, attr, { label: attr.name });
    return {
      ...attr,
      label:
        this._translations?.[attr.name]?.[this._currentLanguage] || attr.name,
      values: !Array.isArray(attr.values)
        ? attr.values
        : attr.values.map((el) =>
            Object.assign({}, el, {
              label:
                this._translations?.[
                  attr.type.toLowerCase() === ATTRIBUTE_TYPES.string
                    ? el.label
                    : el.name
                ]?.[this._currentLanguage] ||
                (attr.type.toLowerCase() === ATTRIBUTE_TYPES.string
                  ? el.label
                  : el.name),
            })
          ),
    };
  }

  _prepThumbnails(attr) {
    if (attr.type.toLowerCase() !== 'asset') return attr;
    const { useProxy, proxyDomainChina } = connection.getConnection();
    const attribute = JSON.parse(JSON.stringify(attr));
    attribute.values = attribute.values.map((el) => {
      const prepped = { ...el };
      if (prepped.metadata._thumbnail)
        prepped.metadata._thumbnail = `${
          useProxy ? proxyDomainChina : 'http://preview.threekit.com'
        }/api/images/webp/200x0/${
          useProxy
            ? `${prepped.metadata._thumbnail.replace(
                'https://preview.threekit.com',
                proxyDomainChina
              )}?cacheScope=123&cacheMaxAge=31536000`
            : prepped.metadata._thumbnail
        }`;
      return prepped;
    });
    return attribute;
  }

  _getAttributeValues(config) {
    return window.threekit.configurator
      .getDisplayAttributes(config)
      .reduce((output, attr) => {
        if (attr.type.toLowerCase() === ATTRIBUTE_TYPES.boolean) return output;

        let valueData = attr.value;

        if (attr.values?.length)
          valueData = attr.values?.find((el) => {
            if (attr.type.toLowerCase() === ATTRIBUTE_TYPES.asset)
              return el.assetId === attr.value.assetId;
            else return el.value === attr.value;
          });

        return Object.assign(output, {
          [attr.name]:
            typeof valueData === 'string' ? { value: valueData } : valueData,
        });
      }, {});
  }

  getForm(config) {
    const attributeArr =
      window.threekit.configurator.getDisplayAttributes(config);
    const attributes = attributeArr.reduce((output, attr) => {
      const prepped = this._prepThumbnails(attr);
      return Object.assign(output, {
        [attr.name]: this._translateAttribute(prepped),
      });
    }, {});

    if (!this._attributeGrouping || config?.groupAttributes === false)
      return attributes;
    const formGroupEntries = Object.entries(this._attributeGrouping);

    const form = {};

    formGroupEntries.forEach(([groupName, attrList]) => {
      form[groupName] = attrList.reduce((output, attrName) => {
        if (!attrName?.length) return output;
        if (!attributes[attrName]) {
          console.log(`${attrName} does not exist in the current Item`);
          return output;
        }
        return Object.assign(output, { [attrName]: attributes[attrName] });
      }, {});
    });

    return form;
  }

  getSku(config) {
    const configuration = window.threekit.configurator.getConfiguration();
    if (configuration[SKU_ATTRIBUTE_NAME])
      return [configuration[SKU_ATTRIBUTE_NAME]];
    const attributeSelections = this._getAttributeValues(config);
    return Object.entries(attributeSelections).reduce(
      (output, [attrName, attrVal]) => {
        let value;

        if (this._codeData[attrName]?.skuValues)
          value = this._codeData[attrName].skuValues?.[attrVal.value] || '';
        else if (attrVal.metadata?.[ATTRIBUTE_SKU_METADATA_KEY]?.length)
          value = attrVal.metadata[ATTRIBUTE_SKU_METADATA_KEY];

        if (value?.length) return [...output, value];
        return output;
      },
      []
    );
  }

  getIndustrialCode(config) {
    const attributeSelections = this._getAttributeValues(config);

    const result = Object.entries(attributeSelections).reduce(
      (output, [attrName, attrVal]) => {
        if (!this._codeData[attrName]?.type) return output;
        let value;
        if (
          this._codeData[attrName].type.toLowerCase() === ATTRIBUTE_TYPES.asset
        ) {
          value = attrVal?.metadata?.code || attrVal?.metadata?.Code || 'NONE';
        } else if (
          this._codeData[attrName].type.toLowerCase() === ATTRIBUTE_TYPES.string
        ) {
          if (this._codeData[attrName].codeValues)
            value = this._codeData[attrName].codeValues[attrVal.value] || '';
          else value = attrVal?.value || '';
        }
        output[this._codeData[attrName].code] = value;

        return output;
      },
      {}
    );

    return result;
  }

  getReadableConfiguration(config) {
    const attributesArr =
      window.threekit.configurator.getDisplayAttributes(config);
    return attributesArr.reduce((output, attr) => {
      const value = attr.values.find((el) => {
        if (attr.type.toLowerCase() === ATTRIBUTE_TYPES.asset)
          return el.assetId === attr.value.assetId;
        else return el.value === attr.value;
      });
      return Object.assign(output, {
        [attr.name]: {
          value: value?.name || value?.label,
          thumbnail: value?.metadata?._thumbnail || undefined,
        },
      });
    }, {});
  }

  takeSnapshots = async (snapshotsConfig) => {
    const size = snapshotsConfig?.size || DEFAULT_CAMERA_CONFIG.size;
    const format = snapshotsConfig?.format || DEFAULT_CAMERA_CONFIG.format;
    const attributeName =
      snapshotsConfig?.attributeName || DEFAULT_CAMERA_CONFIG.attributeName;

    let snapshotsRaw;

    const cameras = window.threekit.configurator
      .getDisplayAttributes()
      .find((el) => el.name === attributeName)
      ?.values.filter((el) => el.tags.includes('snapshot'));

    const currentCamera =
      window.threekit.configurator
        .getDisplayAttributes()
        .find((el) => el.name === attributeName)?.value || undefined;
    const cameraPosition = getCameraPosition(window.threekit.player.camera);

    snapshotsRaw = await getSnapshots(cameras, snapshotsConfig);

    await window.threekit.configurator.setConfiguration({
      [attributeName]: currentCamera,
    });

    setCameraPosition(window.threekit.player.camera, cameraPosition);

    const files = Object.entries(snapshotsRaw).reduce(
      (output, [key, el], idx) => {
        const file = dataURItoFile(el, `${key}.${format}`);
        return Object.assign(output, { [key]: file });
      },
      {}
    );
    return Promise.resolve(files);

    function getSnapshots(cameras) {
      let snapshots = {};
      return cameras.reduce((snapshotPromise, camera) => {
        return snapshotPromise.then(
          () =>
            new Promise(async (resolve) => {
              if (camera)
                await window.threekit.configurator.setConfiguration({
                  [attributeName]: { assetId: camera.assetId },
                });
              const snapshotStr = await window.threekit.player.snapshotAsync({
                size,
                mimeType: `image/${SNAPSHOT_FORMATS[format]}`,
              });
              snapshots[camera.name] = snapshotStr;
              resolve(snapshots);
            })
        );
      }, Promise.resolve(snapshots));
    }
  };

  getOutputs(config) {
    const preppedConfig = Object.assign(
      {
        sku: { includeHidden: false },
        industrialCode: { includeHidden: true },
        readableConfiguration: { includeHidden: true },
      },
      config
    );

    const sku = this.getSku(preppedConfig.sku);
    const industrialCode = this.getIndustrialCode(preppedConfig.industrialCode);
    const readableConfiguration = this.getReadableConfiguration(
      preppedConfig.readableConfiguration
    );
    const threekitConfiguration =
      window.threekit.configurator.getConfiguration();

    return {
      sku,
      industrialCode,
      readableConfiguration,
      threekitConfiguration,
    };
  }

  saveConfiguration(data = {}, cameraConfig) {
    return new Promise(async (resolve) => {
      const attachments = await this.takeSnapshots(cameraConfig);

      let metadataPrepped = Object.assign({}, data, this.getOutputs());
      let configuration = window.threekit.configurator.getConfiguration();
      configuration = Object.entries(configuration).reduce(
        (output, [attrName, attrData]) =>
          attrName.startsWith('_')
            ? output
            : Object.assign(output, { [attrName]: attrData }),
        {}
      );

      const response = await http.postConfiguration({
        configuration: configuration,
        metadata: metadataPrepped,
        attachments,
      });

      const params = Object.assign(utils.getParams(), {
        [TK_SAVED_CONFIG_PARAM_KEY]: response.shortId,
      });
      const url = window.location.href.replace(window.location.search, '');

      const output = {
        ...response,
        resumableUrl: `${url}${utils.objectToQueryStr(params)}`,
        thumbnailUrls: [
          `${this._config.threekitEnv}/api/files/hash/${response.thumbnail}`,
        ],
      };

      resolve(output);
    });
  }
}

export default Controller;
