import { PROXY_DOMAIN_CHINA } from './constants';

class ThreekitConnection {
  constructor() {
    this._authToken = undefined;
    this._orgId = undefined;
    this._assetId = undefined;
    this._threekitEnv = 'https://admin-fts.threekit.com';
    this._proxyDomainChina = undefined;
    this._useProxy = false;
  }

  async connect(config) {
    const preppedConfig = {
      ...config,
      authToken: this._authToken || config.authToken,
      orgId: this._orgId || config.orgId,
    };
    this._authToken = preppedConfig.authToken;
    this._orgId = preppedConfig.orgId;
    this._assetId = preppedConfig.assetId;
    if (preppedConfig.threekitEnv)
      this._threekitEnv = `https://${preppedConfig.threekitEnv}`;

    if (this._threekitEnv.includes('preview'))
      this._proxyDomainChina = `${PROXY_DOMAIN_CHINA}/preview`;
    else if (this._threekitEnv.includes('admin-fts'))
      this._proxyDomainChina = `${PROXY_DOMAIN_CHINA}/fts`;
    else {
      const env = this._threekitEnv
        .replace('https://', '')
        .split('.threekit')[0];
      this._proxyDomainChina = `${PROXY_DOMAIN_CHINA}/${env}`;
    }
    if (preppedConfig.useProxy) this._useProxy = true;
  }

  getConnection() {
    if (!this._authToken)
      throw new Error('Connection has not been established');
    return {
      authToken: this._authToken,
      orgId: this._orgId,
      assetId: this._assetId,
      threekitEnv: this._threekitEnv,
      proxyDomainChina: this._proxyDomainChina,
      useProxy: this._useProxy,
    };
  }
}

const connection = new ThreekitConnection();

export default connection;
